body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type="radio"] {
  display:none;
}
input[type="radio"] + label {
  font-family:Arial, sans-serif;
}

input[type="radio"]:checked + label span {

  width: 35px;
  height: 35px;
  opacity: 1  ;
  
}
input[type="radio"] + label span {
  display:inline-block;
  width:30px;
  height:30px;
  margin:-2px 2px 0 0;
  vertical-align:middle;
  cursor:pointer;
  border-radius:5px;
  opacity: 0.5;
  border: 0.5px solid black;


}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.left .MuiListItemText-primary {
    background-color:#0699d2;
    border-radius: 5px;
    padding:10px;
    display: inline-block;  
    color: #fff;
}

.right .MuiListItemText-primary {
    background-color:#3a87da;
    border-radius: 5px;
    padding:10px;
    display: inline-block;
    color: #fff;
    
  
}

.right .MuiListItemText-primary {
    background-color:#8deb09;
    border-radius: 5px;
    padding:10px;
    display: inline-block;
    color: #fff;
    
  
}
.MuiToolbar-root {
    color: #333;
    align-self: end;
    height: 100%;
  }

  .MuiAppBar-root {
   height:100px;
 } 

 .MuiContainer-root.hero	{
    background: url("/images/site/teddy.png") top right  no-repeat  ;
    background-size: cover  ;


 
    
}



.MuiContainer-root.heroMobile{
    background: url("/images/site/back-mobile.png")  top left  no-repeat  ;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
   
    
}

.buttonRight{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.buttonLeft{
    width: 100%;
   background-color: #4ABDF0;
   color:#fff;
   padding: 5px;
   border: none;
   border-radius: 5px;
font-size: large;

}

.homeMobileButton{
    width: 300px;
    color:#333;
    border: 2px solid #333;
    padding: 20px;
    margin: 10px;
    background-color: transparent;
    border-radius: 5px;

}

.homeMobileButton a {

    color:#333;
    text-decoration: none;


}
.button{
    width: 200px;
    color: #fff;
    border: 2px solid  #fff;
    padding: 20px;
    margin: 10px;
    background-color: transparent;
    border-radius: 5px;
}

.button:hover{
border:  2px solid blue;
}

.homeSearch{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 400px;
    min-height: 70vh;
    margin-left: 50px;
}
.left .MuiListItemText-primary {
    background-color:#BADDE0;
    border-radius: 5px;
    padding:10px;
    display: inline-block;  
  
}

.right .MuiListItemText-primary {
    background-color:#79d9e2;
    border-radius: 5px;
    padding:10px;
    display: inline-block;
    
  
}
.MuiListItemText-root.details {
  display: inline-flex;
  justify-content: space-between;
}

.details .MuiListItemText-primary{
 
  font-size: 12px;
}
.details .MuiListItemText-secondary{

    font-size: 12px;
  }

  label#select {
    font-size: 21px;
}

a.homeLink {
  text-decoration: none;
  width:100%;
  color: #fff;
  text-align: center;
}

  
