.MuiToolbar-root {
    color: #333;
    align-self: end;
    height: 100%;
  }

  .MuiAppBar-root {
   height:100px;
 } 

 .MuiContainer-root.hero	{
    background: url("/images/site/teddy.png") top right  no-repeat  ;
    background-size: cover  ;


 
    
}



.MuiContainer-root.heroMobile{
    background: url("/images/site/back-mobile.png")  top left  no-repeat  ;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
   
    
}

.buttonRight{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.buttonLeft{
    width: 100%;
   background-color: #4ABDF0;
   color:#fff;
   padding: 5px;
   border: none;
   border-radius: 5px;
font-size: large;

}

.homeMobileButton{
    width: 300px;
    color:#333;
    border: 2px solid #333;
    padding: 20px;
    margin: 10px;
    background-color: transparent;
    border-radius: 5px;

}

.homeMobileButton a {

    color:#333;
    text-decoration: none;


}
.button{
    width: 200px;
    color: #fff;
    border: 2px solid  #fff;
    padding: 20px;
    margin: 10px;
    background-color: transparent;
    border-radius: 5px;
}

.button:hover{
border:  2px solid blue;
}

.homeSearch{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 400px;
    min-height: 70vh;
    margin-left: 50px;
}