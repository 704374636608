.MuiListItemText-root.details {
  display: inline-flex;
  justify-content: space-between;
}

.details .MuiListItemText-primary{
 
  font-size: 12px;
}
.details .MuiListItemText-secondary{

    font-size: 12px;
  }

  label#select {
    font-size: 21px;
}

a.homeLink {
  text-decoration: none;
  width:100%;
  color: #fff;
  text-align: center;
}

  