.left .MuiListItemText-primary {
    background-color:#0699d2;
    border-radius: 5px;
    padding:10px;
    display: inline-block;  
    color: #fff;
}

.right .MuiListItemText-primary {
    background-color:#3a87da;
    border-radius: 5px;
    padding:10px;
    display: inline-block;
    color: #fff;
    
  
}

.right .MuiListItemText-primary {
    background-color:#8deb09;
    border-radius: 5px;
    padding:10px;
    display: inline-block;
    color: #fff;
    
  
}